<template>
  <v-card class="rounded-0 max-h-content-container position-relative overflow-hidden text-sm">
    <div
      class="d-flex align-center justify-center mx-6 my-5"
      style="min-height:600px"
    >
      <div
        class="h-full"
      >
        <v-card>
          <v-card-title>{{ name }}</v-card-title>
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup() {
    return {
      name: 'Marketplace Checkout (Marketplace)[P]',
    }
  },
})
</script>

<style lang="scss">
</style>
